<template>
    <div class="page-warp gray-page min-page">
        <van-form v-model="submitForm" ref="submitForm" @submit="submitSave">
            <div class="row page-model-title">发票抬头信息</div>
            <van-field
                v-model="submitForm.accountName"
                label="公司名称"
                required
                placeholder="请输入公司名称"/>
            <van-field
                v-model="submitForm.accountName"
                label="税后"
                required
                placeholder="请输入开户行名称"/>
            <van-field
                v-model="submitForm.account"
                label="电话"
                required
                placeholder="请输入开户账号"/>
            <van-field
                v-model="submitForm.accountName"
                label="注册地址"
                required
                placeholder="请输入开户行名称"/>
            <van-field
                v-model="submitForm.accountName"
                label="开户银行"
                required
                placeholder="请输入开户行名称"/>
            <van-field
                v-model="submitForm.account"
                label="银行账户"
                required
                placeholder="请输入开户账号"/>
            <div class="row bottom-btns mt-30">
                <van-button round block type="info" native-type="submitSave">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<script>
import { accountGet,accountUpdate } from '@/api/user'
export default {
    name:'invoiceAdd',
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            submitForm:{
                accountName:'',
                account:'',
            }
        };
    },
    created(){
        this.getAccountDetail();
    },
    methods: {
        getAccountDetail(){
            accountGet().then((res)=>{
                this.submitForm.accountName = res.data.accountName;
                this.submitForm.account = res.data.account;
                this.submitForm.name = res.data.name;
            })
        },
        submitSave(){
            if(!this.submitForm.accountName){
                this.$toast.fail('开户行不能为空');
                return false;
            }
            if(!this.submitForm.account){
                this.$toast.fail('开户账号不能为空');
                return false;
            }
            accountUpdate(this.submitForm).then(response => {
                this.$toast.success(response.msg);
            });
        }
    },
};
</script>
<style lang="less" scoped>
.page-model-title {
    text-align: left; line-height: 40px; font-size: 14px; color: #080808;
    box-sizing: border-box; padding:0px 10px;
    span {
        color: #278BF9; float: right;
    }
}
</style>